.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h1,h2,h3,h4,h5,h6,li,ul,td,p{
  color:black;
}
.facebookLink{
  width:30px;
}
.navbarBox{
  background-color:#c70000;
}
.navbar{
  max-width:85%;
  padding-right:7.5% !important;
  padding-left:7.5% !important;
}
footer{
  background-color:#800000;
  margin-top:15px;
  padding-top:15px;
  padding-bottom:15px;
}
.pictureTitle{
  color:#800000;
}
.plaagTableCard{
  align-content: center;

}
.plaagLink{
  position: absolute;
  right:7.5%;
}
.plaagTable{
  border:3px solid #800000;
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom:1rem;
  margin-top:1rem;
  max-width:100%
}
.navitem{
  padding-left:4px;
  padding-right:4px;

  margin:5px;
  border-width:2px;
  border-style:solid;
  border-color:#bd0000;
  border-radius:5px;
}
.navitem:hover{
  background-color:#bd0000;
}
.navlink{
  text-decoration: none;
  color:black;
  font-weight:600;
}
.bodyBox{
  max-width:85%;
  padding-right:7.5%;
  padding-left:7.5%;
}
@media (max-width:800px){
  .MuiBox-root.MuiBox-root-12.navitem.navitem1{
    max-width:60%;

  }
  .MuiBox-root.jss10.navitem.navitem1{
    max-width:60%;

  }
  .bodyBox{
    max-width:100%;
    padding:0;
  }
  .navitem{
    width:100%;
    max-height:3rem;
  }
  .navitem1{
    position: absolute;
  }
  .navlink{
    max-width:100%;
    overflow:auto;
  }
  .navbar{
    height:8rem;
  }
  td{
    white-space: nowrap;
  }
  .MuiCard-root{
    overflow:auto !important;
  }
}
.navbarlogo {
  width: 6rem;
  height: 6rem;
  margin-right: 0.5rem;

}
.aboutBoxes{
  height:16rem;
  border:3px solid #800000;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
